<template>
	<Maps></Maps>
</template>

<script>
import { standardMindmapSteps } from "@/utils/steps";
import Maps from "./Maps";
import { stepsManageMixin } from "@/mixins/stepsManageMixin";

export default {
	name: "ChooseTopicAltStandardMindmap",

	mixins: [stepsManageMixin],

	components: {
		Maps,
	},

	created() {
		this.$eventBus.$on("smm-redirect-back", async (payload) => {
			this.redirectBack();
		});
		this.$eventBus.$on("smm-redirect-next", async (payload) => {
			this.redirectNext();
		});
		this.$eventBus.$on("set-steps-to-initial", async (payload) => {
			await this.saveSteps({
				current_step: 1,
				completed_step: 0,
			});
		});
		this.$eventBus.$on("smm-finish-work", async (payload) => {
			this.$emit("smm-process-completed");
		});
	},

	mounted() {
		// This function is in mixin
		this.getAndSetSteps();
	},

	computed: {
		featureSteps() {
			return standardMindmapSteps;
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("smm-redirect-back");
		this.$eventBus.$off("smm-redirect-next");
		this.$eventBus.$off("smm-finish-work");
		this.$eventBus.$off("set-steps-to-initial");
	},
};
</script>
