<template>
	<div>
		<StandardMindmap
			@smm-process-completed="openFinishDialog()"
		></StandardMindmap>

		<!-- Ask before close mind map  -->
		<FinishConfirmationDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@confirm="proceedToNext()"
		></FinishConfirmationDialog>
	</div>
</template>

<script>
import StandardMindmap from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/standardMindmap/Index";
import { chooseTopicAlternativesMixin } from "@/mixins/chooseTopicAlternativesMixin";

export default {
	name: "ChooseTopicAltStandardMindmapPage",

	mixins: [chooseTopicAlternativesMixin],

	components: {
		StandardMindmap,
	},
};
</script>

<style></style>
